<template>
  <div class="AdminView">
    <div class="title">
      Memoransier Admin Page
    </div>

    <div>
      <div
        v-for="game of games"
        :key="game.id"
        class="Game">
        <pre>{{ game }}</pre>
        <br>
        <Button
          label="Open"
          @click="gotoGame(game.id)" />
        <Button
          v-if="game.published"
          label="Unpublish"
          @click="unpublishGame(game.id)" />
        <Button
          v-else
          label="Publish"
          @click="publishGame(game.id)" />
        <Button
          label="Delete"
          @click="deleteGame(game.id)" />
      </div>
    </div>

    <div>
      <Button
        label="Create game"
        @click="createGame" />
      <Button
        label="Refresh"
        @click="refresh" />
    </div>
  </div>
</template>

<script>
import CreateGamePopup from '../components/CreateGamePopup';
import Button from '@/modules/social/components/Button';

import memoransierData from '../data/';

function getNumberOfQuestions(dataId) {
    const data = memoransierData[dataId];
    let count = 0;
    data.memos.forEach(m => {
        count += m.pairs.length;
    });
    return count;
}

export default {
    components: { Button },
    computed: {
        games() {
            return this.$store.state.moduleMemoransier.games
            .filter(g => g.teamId === null)
            .filter(g => !g.options.teamId);
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.$store.dispatch('moduleMemoransier/refresh');
        },
        async createGame() {
            const result = await this.$modal
                .open({
                    component: CreateGamePopup,
                    target: 'app-modal'
                })
                .waitDone();
            if (result === 'cancel') {
                return;
            }

            result.options.numberOfQuestions = getNumberOfQuestions(result.options.dataId);

            this.$store.dispatch('moduleMemoransier/createGame', {
                name: result.name,
                role: result.role,
                options: result.options
            });
        },
        deleteGame(gameId) {
            this.$store.dispatch('moduleMemoransier/deleteGame', gameId);
        },
        publishGame(gameId) {
            this.$store.dispatch('moduleMemoransier/publishGame', gameId);
        },
        unpublishGame(gameId) {
            this.$store.dispatch('moduleMemoransier/unpublishGame', gameId);
        },
        gotoGame(gameId) {
            this.$router.push({
                name: 'MemoransierLobby',
                params: { gameId }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.AdminView {
    background: $dashboard-sectionGradient;
    color: white;
    padding: 1em;
    overflow-y: auto;
}
.title {
    font-weight: 600;
    text-align: center;
    font-size: 120%;
}
.Button {
    margin: 0.5em;
}
.Game {
    margin: 1em;
    padding: 1em;
    background-color: $dashboard-itemBackground; //gray;
    border-radius: $dashboard-itemBorderRadius;
}
</style>
