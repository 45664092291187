<template>
  <div class="CreateGamePopup">
    <div>Create Game</div>
    <br>

    <br>
    <div>
      Name: <input
        id="checkbox"
        v-model.trim="name"
        type="text">
    </div>
    <div>
      GameData:
      <select v-model="dataId">
        <option
          disabled
          value="">
          Please select one
        </option>
        <option
          v-for="option of dataIds"
          :key="option">
          {{ option }}
        </option>
      </select>
    </div>
    <div>
      Role: <input
        id="checkbox"
        v-model.trim="role"
        type="text">
    </div>
    <br>
    <div>
      <Button
        label="Cancel"
        @click="$emit('signal', 'cancel')" />
            &nbsp;
      <Button
        label="Create"
        :disabled="!valid"
        @click="create()" />
    </div>
  </div>
</template>

<script>
import Button from '@/modules/social/components/Button';

export default {
    components: { Button },
    data() {
        return {
            name: '',
            role: '',
            dataId: ''
        };
    },
    computed: {
        valid() {
            return this.name && this.role && this.dataId;
        }
    },
    created() {
        this.dataIds = ['storebrand', 'techdata', 'aim2north', 'xledger', 'sats', 'tekna', 'foobar1', 'foobar2', 'technipfmc'];
    },
    methods: {
        create() {
            this.$emit('signal', { name: this.name, role: this.role, options: { dataId: this.dataId } });
        }
    }
};
</script>

<style scoped lang="scss">
.CreateGamePopup {
    padding: 1em;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.5em;
    background-color: white;
}
</style>
